<template>
    <!-- 导航 -->
    <page-head :title="store.state.teamData.name"/>
    <content-bg>
        <template v-slot:content>
            <div class="menuList">
                <div v-if="showData && showData.showList"
                     v-for="(item,index) in showData.showList"
                     class="menuItem" @click="menuClick(item)">
                    <index-number>
                        <template v-slot:number>{{ index + 1 }}</template>
                    </index-number>
                    <menu-card>
                        <template v-slot:icon><img class="icon" :src="item.icon" alt=""></template>
                        <template v-slot:label>
                            <p class="label">{{ item.text }}</p>
                        </template>
                    </menu-card>
                </div>

            </div>
        </template>
    </content-bg>
    <pagination-com :current-page="showData.page" :total-page="showData.totalPage"
                    @changePage="changePage"></pagination-com>
</template>

<script>
import {useRouter} from "vue-router";
import {getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";

export default {
    name: "menu",
    setup() {

        const router = useRouter()
        const {proxy} = getCurrentInstance()
        const store = useStore()

        const state = reactive({

            menuList: [],

            // 显示的菜单，分页
            page: {
                pageNum: 1,
                pageSize: 10
            },
            showData: {}
        });

        const getMenuList = () => {
            let list = [];

            let menuList = proxy.$aiJson.dataReport;

            for (const menuListKey in menuList) {
                if (!menuList[menuListKey].hidden) {
                    list.push({
                        ...menuList[menuListKey]
                    })
                }
            }

            state.menuList = [...list];
            state.showData = proxy.$utils.paginationFn(state.menuList, state.page.pageNum, state.page.pageSize);
        }

        getMenuList();

        const changePage = (page) => {
            state.page.pageNum = page;
            state.showData = proxy.$utils.paginationFn(state.menuList, state.page.pageNum, state.page.pageSize);
        }

        const menuClick = (item) => {
            router.push({
                ...item.router
            })
        }


        return {
            ...toRefs(state),
            changePage,
            menuClick,
            store
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .menuItem {
    width: 240px;
    height: 320px;
    margin-right: 26px;
    position: relative;
    box-sizing: border-box;
    margin-bottom: 36px;
    text-align: center;

    &:nth-child(5n+1) {
        margin-left: 25px;
    }

    .cardItem {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .icon {
        width: 96px;
        height: 96px;
        margin-top: 98px;
        margin-bottom: 10px;
    }

    .label {
        font-size: 26px;
        line-height: 36px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: PingFangMedium;
        color: #FFFFFF;
    }
}

::v-deep.indexNum {
    left: 16px;
    top: 16px;
}

::v-deep.pagination {
    position: absolute;
    left: 592px;
    top: 978px;
}

.menuList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 54px;
    width: 100%;
}
</style>